import { isProduction, isStaging } from "@wpf-ui/utils";
const BASE_URLS = {
  PRODUCTION: {
    likesAdmin: "https://admin.shelikes.jp",
    likes: "https://shelikes.jp",
    profile: "https://profile.shelikes.jp",
    front: "https://creators.shelikes.jp",
    admin: "https://admin.creators.shelikes.jp",
  },
  STAGING: {
    likesAdmin: "https://staging.admin.shelikes.jp",
    likes: "https://staging.shelikes.jp",
    profile: "https://staging.profile.shelikes.jp",
    front: "https://staging.creators.shelikes.jp",
    admin: "https://staging.admin.creators.shelikes.jp",
  },
  LOCAL: {
    likesAdmin: "http://localhost:8090/admin",
    likes: "http://localhost:8090",
    profile: "http://localhost:3001",
    front: "http://localhost:3002",
    admin: "http://localhost:3003",
  },
};
export const likesUrl = (path: string): string => {
  if (isProduction()) {
    return `${BASE_URLS.PRODUCTION.likes}/${path}`;
  }
  if (isStaging()) {
    return `${BASE_URLS.STAGING.likes}/${path}`;
  }
  return `${BASE_URLS.LOCAL.likes}/${path}`;
};

export const likesAdminUrl = (path: string): string => {
  if (isProduction()) {
    return `${BASE_URLS.PRODUCTION.likesAdmin}/${path}`;
  }
  if (isStaging()) {
    return `${BASE_URLS.STAGING.likesAdmin}/${path}`;
  }
  return `${BASE_URLS.LOCAL.likesAdmin}/${path}`;
};

export const wpfProfileUrl = (path: string): string => {
  if (isProduction()) {
    return `${BASE_URLS.PRODUCTION.profile}/${path}`;
  }
  if (isStaging()) {
    return `${BASE_URLS.STAGING.profile}/${path}`;
  }
  return `${BASE_URLS.LOCAL.profile}/${path}`;
};

export const isValidRedirectUrl = (redirectUrl: string): boolean => {
  try {
    const urlObj = new URL(redirectUrl);
    if (isProduction()) {
      return urlObj.origin === BASE_URLS.PRODUCTION.front;
    } else if (isStaging()) {
      return urlObj.origin === BASE_URLS.STAGING.front;
    } else {
      return urlObj.origin === BASE_URLS.LOCAL.front;
    }
  } catch (e: unknown) {
    return false;
  }
};
